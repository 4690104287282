:root {
    --main-bg-color: #121212;
    /* --accent-color: #2c7be0; */
    /* --accent-color: #FFFF00; */
    --accent-color: #ffffff;
    /* --text-color: #ffff00; */
    --text-color: #ffffff;
  }

  .flex {
    background-color: var(--main-bg-color);
  }
  
  .text-5xl {
    color: var(--accent-color);
  }
  
  .text-lg {
    color: var(--text-color);
  }

  body {
    font-family: 'Roboto', sans-serif;
  }
  
  .text-custom {
    /* color: #FFf200; */
    color: #FFff00;
    /* color: #FFffff; */
  }

  .text-opacity-10 {
    opacity: 0.1;
  }

  .text-opacity-20 {
    opacity: 0.2;
  }

  .text-opacity-30 {
    opacity: 0.3;
  }

  .text-opacity-40 {
    opacity: 0.4;
  }

  .text-opacity-50 {
    opacity: 0.5;
  }

  .h2-font {
    font-size: 2em;
  }
  
  .gradient-bar {
    background: linear-gradient(to right, blue, pink);
    /* background: linear-gradient(to right, rgba(0, 0, 255, 0.521), pink); */
  }